/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import { FooterComponent as SourceFooterComponent } from 'SourceComponent/Footer/Footer.component';
import { ReactElement } from 'Type/Common.type';

import { FOOTER_CMS, NEWSLETTER_COLUMN } from './Footer.config';
import { FooterRenderColumn } from './Footer.type';

import './Footer.override.style';

/** @namespace Poprawa/Component/Footer/Component */
export class FooterComponent extends SourceFooterComponent {
    renderCopyrightContent(): ReactElement {
        const { copyright } = this.props;

        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    { copyright }
                </span>
                <CmsBlock identifier="footer_copyright_payments" blockType="Payments" key="Payments" />
            </ContentWrapper>
        );
    }

    renderColumn(column: FooterRenderColumn, i?: number): ReactElement {
        const {
            title,
            columnActiveKey,
            items,
            isItemsHorizontal,
            mods = {},
        } = column;

        const contentMods = isItemsHorizontal ? { direction: 'horizontal' } : {};

        if (columnActiveKey && !(columnActiveKey in this.props)) {
            return null;
        }

        return (
            <div block="Footer" elem="Column" mods={ mods } key={ i }>
                <h3 block="Footer" elem="ColumnTitle">
                    { title }
                </h3>
                <div
                  block="Footer"
                  elem="ColumnContent"
                  mods={ contentMods }
                >
                    { items.map(this.renderColumnItem.bind(this)) }
                </div>
            </div>
        );
    }

    renderCmsBlock(): ReactElement {
        return (
            <div
              block="Footer"
              elem="CmsBlockWrapper"
              mix={ { block: 'Footer', elem: 'Content' } }
            >
                <div
                  block="Footer"
                  elem="Columns"
                  mix={ { block: 'ContentWrapper' } }
                >
                    <CmsBlock identifier={ FOOTER_CMS } blockType="Footer" key="Footer" />
                    { this.renderColumn({
                        ...NEWSLETTER_COLUMN,
                        cmsId: '',
                        mods: { isNewsletter: true },
                    }) }
                </div>
            </div>
        );
    }

    renderContent(): ReactElement {
        const { footer_content: { footer_cms = undefined } = {} } = window.contentConfiguration || {};

        if (footer_cms) {
            return this.renderCmsBlockWrapper();
        }

        return (
            <div block="Footer" elem="Content">
                { this.renderCmsBlock() }
            </div>
        );
    }
}

export default FooterComponent;
