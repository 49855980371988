/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import { mapDispatchToProps, mapStateToProps, NewsletterSubscriptionContainer as SourceNewsletterSubscriptionContainer } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.container';

/** @namespace Poprawa/Component/NewsletterSubscription/Container */
export class NewsletterSubscriptionContainer extends SourceNewsletterSubscriptionContainer {
    containerProps() {
        const { isLoading } = this.state;
        const { showErrorNotification } = this.props;

        return { isLoading, showErrorNotification };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsletterSubscriptionContainer);
