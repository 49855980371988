/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import CmsBlockQuery from 'Query/CmsBlock.query';
import CmsBlock from 'SourceComponent/CmsBlock/CmsBlock.component';
import DataContainer from 'Util/Request/DataContainer';

/** @namespace Poprawa/Component/CmsBlock/Container */
export class CmsBlockContainer extends DataContainer {
    static defaultProps = {
        blockType: '',
    };

    state = {
        cmsBlock: {},
    };

    __construct(props) {
        super.__construct(props, 'CmsBlockContainer');
    }

    containerProps() {
        const { blockType, children } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, children };
    }

    componentDidMount() {
        this._getCmsBlock();
    }

    componentDidUpdate(prevProps) {
        const { identifier } = this.props;
        const { identifier: prevIdentifier } = prevProps;

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock() {
        const { identifier } = this.props;

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items }, cmsBlocks }) => {
                if (!items.length) {
                    return;
                }

                if (identifier === cmsBlocks.items[0].identifier) {
                    this.setState({ cmsBlock: items[0] });
                }
            },
        );
    }

    render() {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default CmsBlockContainer;
