/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import { CloseIconComponent as SourceCloseIconComponent } from 'SourceComponent/CloseIcon/CloseIcon.component';

/** @namespace Poprawa/Component/CloseIcon/Component */
export class CloseIconComponent extends SourceCloseIconComponent {
    render() {
        return <CloseIcon />;
    }
}

export default CloseIconComponent;
