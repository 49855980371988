/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import Footer from 'Component/Footer';
import InstallPrompt from 'Component/InstallPrompt';
import SliderWidget from 'Component/SliderWidget';
import CmsPage from 'Route/CmsPage';
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';

import { SLIDER_ID } from './HomePage.config';

import './HomePage.override.style';

export {
    mapStateToProps,
    mapDispatchToProps,
};

/** @namespace Poprawa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    render() {
        return (
            <div block="HomePage">
                <InstallPrompt />
                <div block="HomePage" elem="Slider">
                    <SliderWidget sliderId={ SLIDER_ID } />
                </div>
                <CmsPage { ...this.containerProps() } />
                <Footer isVisibleOnMobile />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
