/* eslint-disable react/forbid-component-props */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { ReactComponent as CheckboxChecked } from 'assets/checkbox/checked.svg';
import { ReactComponent as CheckboxUnchecked } from 'assets/checkbox/unchecked.svg';
import {
    InputHTMLAttributes,
} from 'react';

import { FieldType } from 'Component/Field/Field.config';
import {
    FieldReactEvents,
} from 'Component/Field/Field.type';
import { FieldComponent as SourceFieldComponent } from 'SourceComponent/Field/Field.component';
import { noopFn } from 'Util/Common';

import './Field.override.style';

/** @namespace Poprawa/Component/Field/Component */
export class FieldComponent extends SourceFieldComponent {
    renderMap = {
    // Checkboxes & Radio
        [FieldType.RADIO]: this.renderCheckboxOrRadio.bind(this),
        [FieldType.CHECKBOX]: this.renderCheckbox.bind(this),
        [FieldType.MULTI]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FieldType.EMAIL]: this.renderDefaultInput.bind(this),
        [FieldType.TEXT]: this.renderDefaultInput.bind(this),
        [FieldType.TIME]: this.renderDefaultInput.bind(this),
        [FieldType.DATETIME]: this.renderDefaultInput.bind(this),
        [FieldType.DATE]: this.renderDefaultInput.bind(this),
        [FieldType.PASSWORD]: this.renderDefaultInput.bind(this),
        [FieldType.SUBMIT]: this.renderDefaultInput.bind(this),
        [FieldType.TEL]: this.renderDefaultInput.bind(this),
        [FieldType.NUMBER]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FieldType.FILE]: this.renderFile.bind(this),
        [FieldType.SELECT]: this.renderSelect.bind(this),
        [FieldType.TEXTAREA]: this.renderTextArea.bind(this),
        [FieldType.BUTTON]: this.renderButton.bind(this),
        [FieldType.NUMBER_WITH_CONTROLS]: this.renderNumberWithControls.bind(this),

    };

    renderCheckbox() {
        const {
            type,
            setRef,
            attr: { defaultChecked = false, ...newAttr } = {},
            events: { onChange },
            events,
            isDisabled,
            label,
        } = this.props;

        const {
            id = '',
            checked,
            value = '',
        } = newAttr as InputHTMLAttributes<HTMLInputElement>;
        const elem = type.charAt(0).toUpperCase() + type.slice(1);
        const inputEvents = {
            ...events,
            onChange: onChange || noopFn,
        };

        const isButtonDisabled = (!String(value).match('none') && isDisabled);
        const isChecked = checked || (isButtonDisabled || defaultChecked ? !isDisabled : null);

        return (
            <label htmlFor={ id } block="Field" elem={ `${elem}Label` } mods={ { isDisabled } }>
                <input
                  type="checkbox"
                  ref={ (elem) => setRef(elem) }
                  disabled={ isButtonDisabled ? isDisabled : false }
                  { ...newAttr as InputHTMLAttributes<HTMLInputElement> }
                  { ...inputEvents as FieldReactEvents<HTMLInputElement> }
                  // shipping options have checked attr assigned so prioritize its value
                  defaultChecked={ !!isChecked }
                />
                <CheckboxUnchecked className="Checkbox_unchecked" />
                <CheckboxChecked className="Checkbox_checked" />
                { label }
            </label>
        );
    }
}

export default FieldComponent;
