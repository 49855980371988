/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import EmailIcon from 'Component/EmailIcon/EmailIcon.component';
import { FieldType } from 'Component/Field/Field.config';
import { NewsletterSubscriptionComponent as SourceNewsletterSubscriptionComponent } from 'SourceComponent/NewsletterSubscription/NewsletterSubscription.component';

import './NewsletterSubscription.override.style';

/** @namespace Poprawa/Component/NewsletterSubscription/Component */
export class NewsletterSubscriptionComponent extends SourceNewsletterSubscriptionComponent {
    renderFormBody() {
        const { isLoading } = this.props;

        return (
            <div block="FieldForm" elem="Fieldset" mods={ { isLoading } }>
                <EmailIcon />
                { super.renderFormBody() }
            </div>
        );
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class
    consentSubmit = (...data) => {
        const { onFormSubmit, showErrorNotification } = this.props;

        const consent = document.querySelector('#newsletterConsent');

        if (consent && !consent.checked) {
            showErrorNotification(__('Consent is required'));

            return null;
        }

        return onFormSubmit(...data);
    };

    getFormProps() {
        return {
            onSubmit: this.consentSubmit,
        };
    }

    renderActions() {
        return (
            <button
              type={ FieldType.SUBMIT }
              block="Button"
              mods={ { isHollow: true } }
              aria-label={ __('Submit') }
              id="newsletterSubmit"
            >
                { __('Subscribe') }
            </button>
        );
    }
}

export default NewsletterSubscriptionComponent;
