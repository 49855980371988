/* eslint-disable fp/no-let */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { CmsPageComponent as SourceCmsPageComponent } from 'SourceRoute/CmsPage/CmsPage.component';

import './CmsPage.override.style';

/** @namespace Poprawa/Route/CmsPage/Component */
export class CmsPageComponent extends SourceCmsPageComponent {
    handleIframeMessage(event) {
        if (window.location.pathname !== '/paypo') {
            return;
        }
        const paypoIframe = document.querySelector('#paypoIframe');
        let eventData = event.data;
        let isPageHeightSet = false;

        if (!paypoIframe) {
            return;
        }

        if (typeof eventData === 'string') {
            try {
                eventData = JSON.parse(eventData);
            } catch (error) {
                console.error('Error parsing event data:', error);

                return;
            }
        }

        if (typeof eventData === 'object' && eventData !== null) {
            if ('pageBodyHeight' in eventData) {
                paypoIframe.style.height = `${eventData.pageBodyHeight }px`;
                isPageHeightSet = true;
            } else {
                isPageHeightSet || (paypoIframe.style.height = '5200px');
            }
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.handleIframeMessage);
    }

    componentDidUpdate(prevProps) {
        const { isLoading } = this.props;
        const { isLoading: prevIsLoading } = prevProps;

        if (!isLoading && isLoading !== prevIsLoading) {
            if (window.location.pathname === '/paypo') {
                const iframe = document.getElementById('paypoIframe');

                if (!iframe) {
                    return;
                }
                iframe.contentWindow.postMessage({
                    messageType: 'parentReady',
                }, 'https://start.paypo.pl/');
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleIframeMessage);
    }
}

export default CmsPageComponent;
