/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const RENDER_NEWSLETTER = 'render_newsletter';

export const NEWSLETTER_COLUMN = {
    title: __('Newsletter'),
    columnActiveKey: 'newsletterActive',
    items: [
        {
            render: RENDER_NEWSLETTER,
        },
    ],
};

export const FOOTER_CONTACT_CMS_ID = 'footer-contact';
export const FOOTER_INFORMATION_CMS_ID = 'footer-information';
export const FOOTER_ABOUT_CMS_ID = 'footer-about';
export const FOOTER_SOCIALS_CMS_ID = 'footer-socials';

export const FOOTER_CMS = 'footer-cms';

export const EXPANDED_CONTENT_INDEX = 3;

export const COLUMN_MAP = [
    {
        title: __('GreenoPack'),
        cmsId: FOOTER_CONTACT_CMS_ID,
        items: [],
    },
    {
        title: __('Additional info'),
        cmsId: FOOTER_INFORMATION_CMS_ID,
        items: [],
    },
    {
        title: __('Follow us'),
        cmsId: FOOTER_SOCIALS_CMS_ID,
        items: [],
    },
    {
        title: __('About us'),
        cmsId: FOOTER_ABOUT_CMS_ID,
        items: [],
    },
    NEWSLETTER_COLUMN,
];
